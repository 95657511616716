import { FC, useCallback, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useMst } from '../../../store';
import { observer } from 'mobx-react-lite';

import BigNumber from 'bignumber.js/bignumber';
import cn from 'classnames';

import { Button } from 'components';

import { WalletService } from 'services';

import { ReactComponent as Arrow } from 'assets/img/icons/arrow_accent_down.svg';
import plus from 'assets/img/icons/plus.svg';

import style from './style.module.scss';

export interface cardData {
  backgroundImg: string;
  circleImg: string;
  badge: string;
  quack: string;
  APY: string;
  entryFee: string;
  staked: number | string;
  quackEarned: string;
  value: string;
  tvl: string;
  total: string;
}

interface props {
  handleStakeUnstakeStart: (type: 'stake' | 'unstake') => void;
  cardData: cardData;
  isConnectBtn: boolean;
  isStakeBtn: boolean;
  isHarvestBtn: boolean;
  isUnstakeBtn: boolean;
  handleConnect: () => void;
  handleHarvest: () => void;
}

const CoinCard: FC<props> = observer(
  ({
    cardData,
    handleStakeUnstakeStart,
    isConnectBtn,
    isStakeBtn,
    isHarvestBtn,
    handleConnect,
    handleHarvest,
    isUnstakeBtn,
  }) => {
    const { quack } = useMst();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const handleShowDetails = useCallback(() => {
      setShowDetails(!showDetails);
    }, [showDetails]);
    return (
      <div className={style.card}>
        <div className={style.circleImg}>
          <img src={cardData.circleImg} alt="circle img" />
        </div>
        <div className={style.badge}>
          <img src={cardData.badge} alt="circle img" />
        </div>
        <div className={style.backgroundImg}>
          <img src={cardData.backgroundImg} alt="background img" />
        </div>
        <div className={style.content}>
          <div className={style.title}>{cardData.quack}</div>
          <div className={cn(style.quaqLabel, 'text-sm', 'text-gray')}>Quack</div>

          <div className={style.table}>
            <div className={style.item}>
              <div className={cn(style.colName, 'text-gray', 'text-sm')}>APY</div>
              <div className={cn(style.colValue, 'text-600', 'text-lmd', 'text-orange')}>
                {cardData.APY}
              </div>
            </div>
            <div className={style.item}>
              <div className={cn(style.colName, 'text-gray', 'text-sm')}>Entry Fee</div>
              <div className={cn(style.colValue, 'text-600', 'text-lmd', 'text-orange')}>
                {cardData.entryFee}%
              </div>
            </div>
          </div>

          <div className={style.text_container}>
            <div className={cn(style.value, 'text-gray')}>QUACK Staked</div>
            <div className={cn(style.yellowValue, 'text-orange', 'text-600 text-ellipsis')}>
              {new BigNumber(cardData.staked).toFormat(2)}
            </div>
            <div className={cn(style.value, 'text-gray')}>
              ${(+cardData.staked * +quack.data.price).toFixed(2)}
            </div>
          </div>
          <div className={style.text_container}>
            <div className={cn(style.value, 'text-gray')}>Quack Earned</div>
            <div className={cn(style.yellowValue, 'text-orange', 'text-600 text-ellipsis')}>
              {new BigNumber(cardData.quackEarned).toFormat(2, 1)}
            </div>
            <div className={cn(style.value, 'text-gray')}>
              ${new BigNumber(+cardData.quackEarned * +quack.data.price).toFixed(2, 1)}
            </div>
          </div>

          {isConnectBtn ? (
            <Button className={style.button} color="filled" onClick={handleConnect} size="medium">
              Connect wallet
            </Button>
          ) : null}
          {isStakeBtn || isUnstakeBtn ? (
            <div className={style.btns}>
              {isStakeBtn ? (
                <Button className={style.button} color="filled" size="medium" href="/levels">
                  Stake
                </Button>
              ) : null}
              {isUnstakeBtn ? (
                <Button
                  className={style.button}
                  color="filled"
                  size="medium"
                  onClick={() => handleStakeUnstakeStart('unstake')}
                >
                  unstake
                </Button>
              ) : null}
            </div>
          ) : null}
          {isHarvestBtn ? (
            <div className={style.actionBar}>
              <Button className={style.unstakePool} onClick={handleHarvest}>
                <div className={cn(style.name, 'text-600', 'text-ssm')}>Harvest</div>
              </Button>
              <Button
                className={style.buttonCircle}
                onClick={() => handleStakeUnstakeStart('stake')}
              >
                <img src={plus} alt="plus" />
              </Button>
              <Button
                className={style.buttonCircle}
                onClick={() => handleStakeUnstakeStart('unstake')}
              >
                <div className={style.minus} />
              </Button>
            </div>
          ) : null}
          <div
            className={style.details_dropdown}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={handleShowDetails}
          >
            <span>Details</span>
            <Arrow />
          </div>
          <CSSTransition
            unmountOnExit
            mountOnEnter
            in={showDetails}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
            classNames="details-show"
          >
            <div className={style.details}>
              <div className={style.row}>
                <div className={cn(style.label, 'text-sm', 'text-gray')}>TVL</div>
                <div className={cn(style.row_value, 'text-orange', 'text-600')}>
                  ${(+cardData.total * quack.data.price).toFixed(2)}
                </div>
              </div>
              <div className={style.row}>
                <div className={cn(style.label, 'text-sm', 'text-gray')}>Total</div>
                <div className={cn(style.row_value, 'text-orange', 'text-600')}>
                  {new BigNumber(cardData.total).toFormat(2)} QUACK
                </div>
              </div>
              <div className={style.row}>
                <div className={cn(style.label, 'text-sm', 'text-gray')}>Harvest Lockup </div>
                <div className={cn(style.row_value, 'text-orange')}>24 Hour (s)</div>
              </div>
              <div
                className={style.row_action}
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={WalletService.addQuackTokenToMM}
              >
                Add Quack to Metamask
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
    );
  },
);

export default CoinCard;
